export default () => ([
  {
    name: "All",
    suffix: "",
    text: "All",
    title: "All Influencers",
    value: 0,
    min: 0,
    max: Infinity
  },
  {
    name: "Nano",
    suffix: "(< 10k)",
    text: "Nano (< 10k)",
    title: "Nano Influencers (< 10k)",
    value: 1,
    min: 1_000,
    max: 10_000
  },
  {
    name: "Micro",
    suffix: "(10k - 100k)",
    text: "Micro (10k - 100k)",
    title: "Micro Influencers (10k - 100k)",
    value: 2,
    min: 10_000,
    max: 100_000
  },
  {
    name: "Macro",
    suffix: "(100k - 500k)",
    text: "Macro (100k - 500k)",
    title: "Macro Influencers (100k - 500k)",
    value: 3,
    min: 100_000,
    max: 500_000
  },
  {
    name: "Mega",
    suffix: "(> 500k)",
    text: "Mega (> 500k)",
    title: "Mega Influencers (> 500k)",
    value: 4,
    min: 500_000,
    max: Infinity
  }
])
